body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  height: 100vh;
}

.p-grid {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.main-container {
  min-height: calc(100% - (118px + 68px));
}

.content-block {
  width: 80%;
}

.pi {
  margin-right: 5px;
}

.pi-only,
.p-checkbox-icon,
.p-toast-icon-close-icon,
.p-button-icon-only .pi {
  margin-right: 0 !important;
}

.aid-nav-link:hover {
  background-color: rgb(220, 234, 242) !important;
}

.active.aid-nav-link:hover {
  background-color: rgb(203, 227, 242) !important;
}

button {
  transition: background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

button:hover {
  background: rgb(214, 156, 32);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
}

button.p-panel-toggler:hover,
button.p-no-hover:hover {
  background: transparent !important;
  box-shadow: none !important;
}

button.p-button-icon-only:hover {
  background: rgb(191, 191, 191) !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
}

div.aid-blu-btn {
  transition: background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

div.aid-blu-btn:hover {
  background: rgb(139, 186, 214) !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
}

button.aid-blu-btn:hover,
button.p-paginator-element:hover {
  background: rgb(43, 105, 153) !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
}

button.p-button-danger:hover {
  background: rgb(199, 14, 29) !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
}

.p-dialog-header {
  padding: 20px;
}

.p-dialog-header .pi {
  font-size: 14px !important;
}

.p-dialog-title {
  font-size: 24px;
}

.p-dialog-header-icons {
  padding: 0 15px;
}

.p-dialog-content div {
  font-size: 22px;
}

button.p-dialog-header-icon:hover {
  background: transparent !important;
  box-shadow: none;
}

.p-dialog-mask {
  z-index: 999999999 !important;
}

tr:nth-last-child(2) .mybids-forecast-dropdown.p-dropdown .p-dropdown-panel,
tr:last-child .mybids-forecast-dropdown.p-dropdown .p-dropdown-panel {
  top: -95px !important;
}

tr:nth-last-child(2)
  .mybids-forecast-dropdown.p-dropdown
  .p-dropdown-trigger-icon,
tr:last-child .mybids-forecast-dropdown.p-dropdown .p-dropdown-trigger-icon {
  transform: rotate(180deg);
}

.p-tooltip .p-tooltip-text {
  width: 100% !important;
}

.tooltip-fixed .p-tooltip-arrow {
  left: 54% !important;
}

.p-tooltip {
  z-index: 2000000000 !important;
}
