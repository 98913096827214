.p-dialog-header {
    padding-left: 15px;
    padding-right: 15px;
    background-color: #367FB6 !important;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.pipeline-column-popover{
    width: "20vw";
    font-size: 16px;
    /* background: #367FB6; */
    border-radius: 20px;
    color: #FFFFFF;
}
.pipeline-column-popover-content{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: left;
}
.pipeline-column-ul{
    line-height: 17px;
    text-decoration-line: underline;
    color: #FFFFFF;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    padding-top: 4px;
}
.pipeline-column-ul>li{
    margin: 0px 0px 10px;
}
#pipe-dialog{
    background: #000000 !important;
    padding: 6px !important;
    border-radius: 20px !important;
}
.p-dialog .p-dialog-content{
    background-color: #000000 !important;
    border-bottom-right-radius: 20px !important;
    border-bottom-left-radius: 20px !important;
    border: none !important;
}
.p-dialog{
    background-color:#000000 !important;
    padding: 3px !important;
    border-radius: 20px !important;
}

.bottom-arrow {
    border-bottom: 5px solid #000000;
}
.bottom-arrow:after {
    content:'';
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-top: 32px solid #000000;
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
}

/* .p-dialog-mask{
    top: 76px !important;
    left: 627px !important;
    justify-content: space-between;
} */