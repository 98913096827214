.img-centered-clipped{
    object-fit: cover;
}
.input-textarea{
  
    border-radius: 20px;
}
.tag {
    font-size: 14px;
    padding: .3em .4em .4em;
    margin: 0 .1em;
    background: #367FB6;
    border-radius: 13px;
  }
  .image-upload-button{
      /* display: flex; */
  }
  .image-upload-button > button{
    background:#ECAC37;
    width:auto;
    border-radius: 14px;
    padding: 3px;
    border: none;
    cursor: pointer;
    margin-top: 10px;
    margin: auto;
  }
  .image-upload-icon {
      font-size: 2.5rem !important;
  }
  .profile-picture-title{
    text-align: center;
    padding-bottom: 7px;
  }
  .profile-image-container{
    border-radius: 50%;
    display: flex;
    height: 120px;
    width: 120px;
    justify-content: center;
    border: 1px solid var(--mist-gray);
    margin: auto;
    margin-bottom: 15px;
    align-items: center;
  }

  .side-panel{
    top:0 !important;
    right:0 !important;
    left:75% !important;
  }
  .side-panel-header{
    height:80px;
    background:#FAEACD;
    box-shadow: -3px 0px 11px rgba(0, 0, 0, 0.14);
    padding: 27px;
    align-items: flex-start;
    margin: auto;
  }
  .p-overlaypanel .p-overlaypanel-content{
    padding: 0px !important;
  }
  
  .field-inputs{
    width:100%;
    height:32px;
    font-size:13px;
    padding: 0px 15px;
    margin: 5px 0px;
    border-radius:20px !important;
  }
  .field-titles{

  }

.edit-user-button{
  background: #FAEACD;
  border-radius: 26.5px ;
  margin: 5px !important;
  height: 35px !important;
  padding: 6px !important;
  border: none;
  width: 74px !important;
}
.buttton-elements{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.field-titles{
  font-size: 12px;
  padding: 5px;
}
.admin-action-button{
  background: none !important;
  color: black !important;
  font-size: 14px !important;
  margin: 0px !important;
  height: 35px !important;
  padding: 3px !important;
  border: none;
}
.admin-action-button:hover{
  box-shadow: none !important;
}
.cell-data{
  text-align: left !important;
  padding-left: 4px !important;
}

.user-status-button-active{
  border-radius: 19px;
  background: #6DC14D;
  border: none;
  padding: 5px 9px;
  color: #FFFFFF;
}
.user-status-button-pending{
  border-radius: 19px;
  background: #ECAC37;
  border: none;
  padding: 5px 9px;
  color: #FFFFFF;
}
.user-status-button-active .user-status-button-pending :hover{
  background-color: inherit;
}

.subscription-container{
  border:1px solid #D9D9D9;
  padding: 20px;
  background: #FFFFFF;
  box-sizing: border-box;
  border-radius: 35px;
  height: 400px;
}
.subscription-header-text{
  text-align: left;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #605F5F;
}
.plus-section{
  display: flex;
  border: 1px solid #ECAC37;
  box-sizing: border-box;
border-radius: 8px;
}
.subscription-footer{
  display: flex;
  text-align: left;
  padding-top: 45px;
  font-size: 13px;
  line-height: 15.85px;
  font-style: normal;
  font-weight: 400;
  color: #605F5F;

}
.subscription-footer-text{
  /* text-align: left;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px; */
}
.sub-button{
  font-weight: bold;
  margin: auto !important;
  height: 40px;
  border-radius: 65px;
  font-size: 1em;
  background-color: rgb(238, 173, 36);
  width: 30rem;
  padding: 8px 24px;
  font-size: 13px;
}

.required{
  color: red;
}