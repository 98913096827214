/* .MuiInputBase-root.MuiInput-root.MuiInput-underline::before {
    border-bottom-color: #FCB017;
}

.MuiInputBase-root.MuiInput-root.MuiInput-underline::after {
    border-bottom-color: #FCB017;
} */

/* .MuiInputAdornment-root.MuiInputAdornment-positionEnd button {
    color: #FCB017;
} */

.new-record {
  background-color: rgb(203, 227, 242) !important;
}

tr.new-record > td.source {
  background-color: rgb(50, 126, 184) !important;
}

.modified-record-bubble,
.modified-record-background,
tr.modified-record > td.modified-column,
tr.modified-record.changed-column-country > td.country,
tr.modified-record.changed-column-location > td.country,
tr.modified-record.changed-column-awardtitle > td.awardtitle,
tr.modified-record.changed-column-awarddescription > td.awarddescription,
tr.modified-record.changed-column-sector > td.sector,
tr.modified-record.changed-column-primeorincumbent > td.primeorincumbent,
tr.modified-record.changed-column-totalestimatedcost > td.totalestimatedcost,
tr.modified-record.changed-column-awardactiontype > td.awardactiontype,
tr.modified-record.changed-column-anticipatedsolicitationreleasedate
  > td.anticipatedsolicitationreleasedate,
tr.modified-record.changed-column-anticipatedawarddate
  > td.anticipatedawarddate,
tr.modified-record.changed-column-smallbusinesssetaside
  > td.smallbusinesssetaside,
tr.modified-record.changed-column-aaplanid > td.aaplanid,
tr.modified-record.changed-column-fiscalyearofaction > td.fiscalyearofaction,
tr.modified-record.changed-column-aaspecialist > td.aaspecialist,
tr.modified-record.changed-column-awardlength > td.awardlength,
tr.modified-record.changed-column-eligibilitycriteria > td.eligibilitycriteria,
tr.modified-record.changed-column-geographicalcodes > td.geographicalcodes,
tr.modified-record.changed-column-categorymanagementcontractvehicle
  > td.categorymanagementcontractvehicle,
tr.modified-record.changed-column-solicitationnumber > td.solicitationnumber,
tr.modified-record.changed-column-cocreation > td.cocreationparsed,
tr.modified-record.changed-column-location > td.location {
  background-color: rgb(250, 234, 203) !important;
}

.final-record {
  background-color: rgb(217, 217, 217) !important;
}

tr.final-record > td.source {
  background-color: rgb(96, 95, 95) !important;
}

.modified-record-background,
tr.modified-record > td.source {
  background-color: rgb(238, 173, 36) !important;
}

body .pi {
  font-size: 10px !important;
  font-weight: bold;
}

.switch-container {
  display: flex;
  align-items: center;
}

.switch-container span {
  font-size: 14px;
  font-weight: bold;
  padding-left: 8px;
}

.button-export {
  position: relative;
}

.button-export-popup {
  position: absolute;
  width: 220px;
  right: 36px;
  background: rgb(50, 126, 184);
  border-radius: 8px;
  z-index: 10;
}

.export-popup-option {
  width: 100%;
  padding: 5px 0;
  background: transparent;
  border: none;
  outline: none;
  font-size: 16px;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
}

.export-popup-option:hover {
  background: rgb(43, 105, 153) !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
}

.export-popup-option:first-child {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.export-popup-option:last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  /* border-top: 2px solid #ffffff; */
}

/* need geo code tooltip to be wider */
.p-tooltip .p-tooltip-text {
  width: 300px;
}

.category-management-tooltip {
  max-width: unset !important;
}

.category-management-tooltip .p-tooltip-text {
  max-width: 850px !important;
  width: 100%;
}

/* pipeline actions */

.pipeline-actions{
    display: flex;
    align-items: center;
    cursor: pointer;
}
.pipeline-button{
    width: 25px;
    height: 25px;
    padding: 2px;
    background-color: transparent;
    cursor: pointer
}

.side-panel-forecast{

  border-radius: 20px !important;
  position: fixed;


}
.side-panel-header-forecast{
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #FFFFFF;
  background: #367FB6;
  width: 390px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.side-panel-content{
  margin-top: -21px;
  border-radius: 20px;
  background: #FFFFFF;
  height: auto !important;
  width: 390px;
  text-align: left;
  display: block;
}
.field-titles-forecast {
  font-size: 12px;
  padding: 6px;
  font-weight: 500;

}
.field-titles-forecast:first-child {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 125px;
  margin-left: 8px;
}
.field-lables{
  display: grid;
}
.field-titles {
  font-size: 12px;
  padding: 6px;}

  .p-component-overlay{
    background: none !important;
  }

  .pipeline-rows{
    text-align: left !important;
    justify-content: flex-start;
  }
  #forecat-input > input{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 22rem;
  }
  #pipeline-overlay{
    top: 9% !important;
    left: 36%!important;
    right: none!important;
    position: fixed !important;
  }
 .p-overlaypanel{
   display: flex !important;
 }