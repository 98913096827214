.sign-out-btn:hover span {
  color: rgb(214, 156, 32);
}

.sign-out-btn:hover i {
  color: rgb(214, 156, 32);
}

#bids > a > p{
  color: #979797 !important;
} 