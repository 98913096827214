.resources-screen {
  width: 100%;
}

.header-subtitle {
  padding: 10px 35px 0 40px;
  font-size: 18px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  color: #000000;
}

.qa-list-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 25px 40px;
}

.qa-list-section .country-list {
  padding: 0 0 25px;
}

.qa-list-section .list-title {
  font-size: 22px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  color: #367fb6;
  margin: 0;
  text-align: left;
  padding-bottom: 10px;
}

.qa-list-section .list-description {
  display: flex;
  padding-left: 15px;
  font-size: 14px;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  text-align: left;
  color: #000000;
}

.qa-list-section .list-subtitle {
  display: flex;
  padding-left: 15px;
  font-size: 16px;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  text-align: left;
  color: #000000;
  padding-bottom: 15px;
}

.qa-list-section .country-list ul {
  display: flex;
  padding: 0 15px;
  margin: 0 0 15px;
  flex-direction: column;
}

.qa-list-section .list-item {
  list-style: none;
  text-align: left;
  font-weight: bold;
  margin: 12px 0;
  cursor: pointer;
}

.qa-list-section .list-item {
  color: rgb(214, 156, 32);
}

.qa-list-section .list-item span {
  cursor: default;
  font-weight: normal;
}
