.createAccountButton {
    background: #367FB6;
    color: #ffffff;
    border-radius: 65px;
    font-family: "Open Sans";
    font-size: .8rem;
}

.landing {
    width: 100%;
    overflow: hidden;
}

.landing .mobile-view {
    display: none;
}

@media (max-width: 760px) {

    .landing .desktop-view {
        display: none;
    }

    .landing .mobile-view {
        display: block;
    }
}

.header-button-container {
    display: flex;
}

@media (max-width: 970px) {
    .header-button-container {
        flex-direction: column;
    }

    .header-button:last-child {
        margin-top: 15px;
    }
}

.header-button {
    display: flex;
    justify-content: flex-end;
    margin-top: 35px;
}

.header-button button {
    width: 182px;
    background: #367FB6;
    color: #ffffff;
    border-radius: 65px;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 15px;
    padding: 8px 5px;
    border: none;
    outline: none;
    margin-right: 15px;
    cursor: pointer;
}

.header-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.header .title h2 {
    margin-bottom: 0;
}

.title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
}

.logo {
    display: flex;
    justify-content: flex-start;
    padding-left: 20px;
    margin-top: 30px;
}

.top-line-block {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FAEACD;
    padding: 40px 20px;
    font-size: 28px;
    font-weight: bold;
    font-family: "Montserrat", sans-serif;
    color: #000000;
    margin-top: 30px;
}

.top-line-block h3 {
    max-width: 1225px;
}

.aid-tasks {
    display: flex;
    justify-content: center;
    padding: 66px 60px;
    /*overflow-x: hidden;*/
    /*overflow-y: unset;*/
}

.aid-tasks-list {
    max-width: 1225px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.aid-task-item {
    display: flex;
    align-items: center;
}

@media (min-width: 950px) {
    .aid-task-item {
        min-width: 830px;
    }
}

.aid-task-item:not(:last-child) {
    margin-bottom: 20px;
}

.aid-task-item .circle {
    width: 7px;
    height: 7px;
    background: #367FB6;
    border-radius: 50px;
    margin-right: 20px;
}

.aid-task-item span {
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: #000000;
}

.scott-section {
    background: #367FB6;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 55px 20px;
}

.scott-section p {
    max-width: 930px;
    width: 100%;
    font-family: "Montserrat", sans-serif;
    font-size: 28px;
    font-weight: 700;
    color: #CCE3F1;
    text-align: left;
    margin: 0;
}

.scott-section div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 930px;
    width: 100%;
}

.scott-section b {
    font-family: "Montserrat", sans-serif;
    font-size: 24px;
    font-weight: 700;
    color: #CCE3F1;
    margin-right: 20px;
}

.scott-section span {
    font-family: "Montserrat", sans-serif !important;
    font-size: 24px !important;
    font-weight: 400;
    color: #CCE3F1;
}

.scott-section img {
    width: 135px;
    height: 135px;
    margin-left: 40px;
}

.title {
    max-width: 1200px;
    width: 100%;
}

.title h2 {
    font-size: 28px;
    font-weight: bold;
    font-family: "Montserrat", sans-serif;
    color: #000000;
    text-align: center;
}

.title h3 {
    font-size: 24px;
    font-weight: bold;
    font-family: "Montserrat", sans-serif;
    color: #000000;
    text-align: center;
    margin-bottom: 0;
}

.description {
    display: flex;
    justify-content: center;
}

.description p {
    font-size: 1rem;
    font-family: "Montserrat", sans-serif;
    text-align: center;
    padding: 20px 40px;
    max-width: 1175px;
}

.aside-img {
    display: flex;
}

.aside-img.yellow-airplane {
    justify-content: flex-start;
    position: relative;
}

.aside-img.yellow-airplane img {
    position: absolute;
    top: -62px;
    left: -230px;
    transform: rotate(-18deg) scale(-1, 1);
}

@media (max-width: 1250px) {

    .aside-img.yellow-airplane img {
        left: -60px;
    }

    .usaid-list span {
        max-width: 340px !important;
    }
}

.mike-section {
    display: flex;
    justify-content: center;
    padding: 60px;
}

.mike-block {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1330px;
    width: 100%;
}

.mike-block img {
    margin-right: 45px;
}

.mike-block div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.mike-block p {
    text-align: left;
    margin: 0;
}

.mike-block h3 {
    font-family: "Montserrat", sans-serif;
    font-size: 28px;
    font-weight: bold;
    margin-top: 0;
}

.opportunities-section {
    background: #367FB6;
    display: flex;
    justify-content: center;
    padding: 60px;
}

.opportunities-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1330px;
    width: 100%;
}

.opportunities-block h3 {
    font-family: "Montserrat", sans-serif;
    font-size: 32px;
    font-weight: bold;
    color: #ECAC37;
    margin-top: 0;
    text-align: left;
}

.opportunities-block p {
    font-family: "Montserrat", sans-serif;
    font-size: 32px;
    font-weight: bold;
    color: #CCE3F1;
    text-align: left;
    max-width: 1000px;
    margin: 0 40px 0 0;
}

.what-is-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 55px 20px 55px 100px;
}

.what-is-section h2 {
    font-family: "Montserrat", sans-serif;
    font-size: 28px;
    font-weight: bold;
    color: #000000;
    margin-top: 0;
    margin-bottom: 30px;
}

.what-is-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1330px;
    width: 100%;
}

.what-is-list {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
}

.what-is-item {
    width: 380px;
    height: 145px;
    border-radius: 10px;
    background: #FAEACD;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.what-is-item:not(:last-child) {
    margin-bottom: 30px;
}

.what-is-item h5 {
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 0;
}

.what-is-item span {
    max-width: 220px;
    width: 100%;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 400;
}

.

.logo-icon {
    margin-bottom: 30px;
}

.logo-icon img {
    width: 50px;
    height: 50px;
}

.tasks .description p {
    margin: 0;
    font-size: 1.5rem;
    max-width: 1240px;
    padding: 0 40px 20px;
}

.tasks-cards-container {
    display: flex;
    justify-content: center;
    padding: 30px 40px;
}

.tasks-cards-container-content {
    display: flex;
    flex-wrap: wrap;
    max-width: 1100px;
    justify-content: center;
    z-index: 1;
}

.task-card {
    max-width: 315px;
    max-height: 145px;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    background: #FAEACD;
    padding: 3.12rem 3.3rem;
    margin: 0 .9rem 1.8rem;
    border-radius: 8px;
}

.landing span {
    font-family: "Montserrat";
    font-size: 1rem;
    font-weight: 400;
}

.tasks {
    padding-top: 90px;
}

.exclusive-block {
    display: flex;
    justify-content: center;
}

.exclusive {
    margin-bottom: 30px;
    max-width: 900px;
    width: 100%;
}

.exclusive p {
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-weight: 500;
    margin: 0;
}

.comment-section {
    background: #367FB6;
    padding: 75px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.comment-section .comment-block {
    max-width: 930px;
    width: 100%;
    margin-top: 0;
    display: block;
}

.comment-section p {
    font-family: "Montserrat", sans-serif;
    font-size: 28px;
    font-weight: bold;
    color: #CCE3F1;
    margin: 0;
    text-align: left;
}

.comment-section div {
    margin-top: 20px;
    display: flex;
}

.comment-section b {
    font-family: "Montserrat", sans-serif;
    font-size: 24px;
    font-weight: bold;
    color: #CCE3F1;
    margin-right: 15px;
    max-width: 315px;
    width: 100%;
}

.comment-section span {
    font-family: "Montserrat", sans-serif;
    font-size: 24px;
    font-weight: 400;
    color: #CCE3F1;
    text-align: left;
}

.plan-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 20px;
}

.plan-section .websites-images {
    margin-bottom: 25px;
}

.plan-block {
    max-width: 1275px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 50px;
}

.free-plan {
    width: 210px;
    height: 160px;
    padding: 35px 0;
    background: #CCE3F1;
    border-radius: 10px;
    margin-right: 5px;
}

.free-plan h5 {
    margin: 0;
    font-family: "Montserrat", sans-serif;
    font-size: 26px;
    font-weight: 700;
    padding-bottom: 20px;
    color: #367FB6;
}

.free-plan span {
    color: #367FB6;
}

.plus-plan {
    width: 210px;
    height: 190px;
    background: #CCE3F1;
    padding-top: 35px;
    border-radius: 10px;
    margin-right: 5px;
}

.plus-plan h5 {
    margin: 0;
    font-family: "Montserrat", sans-serif;
    font-size: 26px;
    font-weight: 700;
    padding-bottom: 20px;
    color: #367FB6;
}

.plus-plan span {
    color: #367FB6;
}

.pro-plan {
    width: 210px;
    height: 240px;
    background: #367FB6;
    padding-top: 35px;
    border-radius: 10px;
}

.pro-plan h5 {
    margin: 0;
    font-family: "Montserrat", sans-serif;
    font-size: 26px;
    font-weight: 700;
    padding-bottom: 20px;
    color: #FFFFFF;
}

.pro-plan span {
    color: #FFFFFF;
}

.plan-description-block {
    max-width: 1275px;
    width: 100%;
}

.plan-description-block h5 {
    width: 100%;
    margin-top: 0;
    margin-bottom: 15px;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
}

.usaid-list {
    margin: 0;
    padding: 0;
}

.usaid-list li {
    height: 50px;
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    padding-left: 30px;
    padding-bottom: 10px;
    border-bottom: 3px solid #ECAC37;
}

.usaid-list li:last-child {
    margin-bottom: 15px;
}

.usaid-list span {
    max-width: 620px;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
}

.usaid-list img:not(:last-child) {
    margin-right: 200px;
}

.usaid-list img:last-child {
    margin-right: 90px;
}

.large-title {
    max-width: 80%;
}

.clock-icon img {
    width: 170px;
    height: 190px;
    transform: rotate(-15deg);
}

.about-why {
    margin-top: 40px;
}

.text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    padding: 0 20px;
}

.main-text {
    max-width: 670px;
}

.sub-text {
    margin-top: 20px;
}

.sub-text span {
    color: #ECAC37;
    font-size: 1.12rem;
    font-weight: bold;
}
.comment-container {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    padding: 120px 40px 40px;
}

.comment-text p {
    font-size: 26px;
    font-weight: bold;
    font-family: "Montserrat", sans-serif;
    color: #000000;
    text-align: start;
    margin-bottom: 0;
    z-index: 10;
}

.comment-text {
    position: relative;
    max-width: 800px;
}

.comment-author {
    display: flex;
    position: relative;
    margin-top: 20px;
    max-width: 830px;
}

.comment-author span:first-child {
    font-size: 24px;
    font-weight: bold;
    font-family: "Montserrat", sans-serif;
    color: #000000;
    text-align: center;
    margin-bottom: 0;
    margin-right: .9rem;
}

.comment-author span:last-child {
    font-size: 24px;
    font-weight: normal;
    font-family: "Montserrat", sans-serif;
    color: #000000;
    text-align: center;
    margin-bottom: 0;
}

.comment-text img:first-child {
    position: absolute;
    bottom: 20px;
    transform: scaleX(-1);
    left: -110px;
    z-index: -1;
}

.comment-text img:last-child {
    position: absolute;
    right: -50px;
    top: 20px;
    z-index: -1;
}

.about-what {
    margin-top: 20px;
}

.advantages-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.advantage-row {
    display: flex;
    justify-content: center;
    max-width: 1200px;
    padding: .9rem 25px;
}

.advantage-row:not(:last-child) {
    border-bottom: 1px solid #367FB6;
}

.advantage-card {
    background: #FAEACD;
    padding: 40px 10px;
    min-width: 280px;
}

.advantage-card p {
    font-size: 1rem;
    font-weight: bold;
    font-family: "Montserrat", sans-serif;
    color: #000000;
    text-align: center;
    margin: 0 0 20px;
}

.advantage-card span {
    font-size: 1rem;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
    color: #000000;
    text-align: center;
}

.advantage-description {
    max-width: 870px;
    padding: 0 35px;
    display: flex;
    align-items: center;
}

.advantage-description p {
    text-align: left;
    font-family: "Montserrat";
    font-size: 1rem;
    font-weight: 400;
}

.aside-img.envelope {
    position: relative;
    max-width: 1700px;
    height: 180px;
}

.aside-img.envelope img {
    width: 210px;
    height: 125px;
    position: absolute;
    right: 20px;
    transform: rotate(10deg);
}

.levels-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
}

.level-card {
    max-width: 1100px;
    width: 100%;
    display: flex;
}

.level-card:nth-child(2) {
    justify-content: flex-end;
}

.level-card:not(:last-child) {
    margin-bottom: 20px;
}

.level-img {
    display: flex;
    justify-content: center;
    align-items: center;
}

.level-img.adapt {
    display: none;
}

.level-img img {
    width: 160px;
    height: 190px;
}

.level-content {
    padding-left: 60px;
    display: flex;
    align-items: center;
}

.level-card:nth-child(2) .level-content {
    padding-left: 0;
    padding-right: 60px;
}

.privilege-list {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.privilege-list h3 {
    width: 100%;
    font-size: 20px;
    text-align: left;
    margin-top: 0;
    margin-bottom: 18px;
}

.privilege-list p {
    margin: 0 0 0 20px;
    max-width: 800px;
    width: 100%;
    text-align: left;
}

.privilege {
    position: relative;
    list-style-type: none;
    text-align: left;
    padding-bottom: 1.12rem;
}

.privilege p {
    font-family: "Montserrat";
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
    padding-left: 30px;
}

.privilege-decorator {
    position: absolute;
    width: .9rem;
    top: 9px;
    border-bottom: 2px solid #ECAC37;
}

.about-how {
    background: #CCE3F1;
    padding: 105px 20px;
}

.about-how .comment-text {
    max-width: 860px;
}

.about-how .comment-text img:first-child {
    bottom: 80px;
    left: -120px;
}

.about-how .comment-text img:last-child {
    right: -120px;
    top: 75px;
}

.about-how .comment-author {
    margin-top: 50px;
}

.about-how .aside-img {
    position: relative;
    justify-content: center;
    align-items: center;
}

.aside-img.blue-airplane img {
    position: absolute;
    right: -55px;
    top: -30px;
}

.about-difference {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.differences-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    max-width: 1100px;
    margin-bottom: 20px;
}

.differences-list .difference {
    margin: 25px 20px;
    width: 300px;
    height: 350px;
    background: #FAEACD;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 .9rem;
    border-radius: 8px;
}

.differences-list .difference span {
    font-weight: 600;
    font-family: "Open Sans", sans-serif;
}

.left-block {
    margin-right: 9rem;
}

.about-difference .title {
    max-width: 950px;
}

.about-difference .title h3 {
    font-size: 20px
}

.about-possibility {
    margin-top: 50px;
    background: #CCE3F1;
    border: 1px solid #979797;
}

.about-possibility .title h2 {
    color: #367FB6;
}

.possibilities-container {
    display: flex;
    justify-content: center;
    margin-bottom: 60px;
}

.possibility-card {
    text-align: left;
    margin-bottom: 20px;
}

.possibility-card .title h3 {
    font-family: "Open Sans", sans-serif;
    font-size: 1.12rem;
    font-weight: 600;
    text-align: left;
    margin-bottom: 10px;
}

.possibility-card .item {
    max-width: 360px;
    height: 55px;
    margin-left: 20px;
    display: flex;
    align-items: center;
}

.possibility-card .item.white {
    border-bottom: 2px solid #FFFFFF;
}

.possibility-card .item.gold {
    border-bottom: 2px solid #ECAC37;
}

.possibility-card .item.blue {
    border-bottom: 2px solid #367FB6;
}

.legend {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 40px;
}

.legend-item {
    position: relative;
    padding-left: 50px;
    padding-bottom: 10px;
}

.legend-item:not(:last-child) {
    padding-right: 30px;
}

.legend-item-decorator {
    position: absolute;
    width: 38px;
    left: 0;
    top: 8px;
}

.legend-item-decorator.white {
    border: 1px solid #ffffff;
}

.legend-item-decorator.blue {
    border: 1px solid #367FB6;
}

.legend-item-decorator.gold {
    border: 1px solid #ECAC37;
}

.footer-buttons {
    padding: 70px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.buttons-container {
    max-width: 800px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer-button span {
    font-family: "Montserrat", sans-serif;
    font-size: 22px;
    font-weight: 700;
}

@media (max-width: 1050px) {



    .aside-img {
        display: none;
    }

    .left-block {
        margin-right: 4rem;
    }
}

@media (max-width: 900px) {

    .possibilities-container {
        flex-direction: column;
        align-items: center;
    }

    .buttons-container {
        flex-direction: column;
    }

    .buttons-container button:first-child {
        margin-bottom: 20px;
    }

    .left-block {
        margin-right: 0;
    }
}

@media (max-width: 800px)  {

    .comment-text img:first-child, .comment-text img:last-child {
        width: 100px;
    }

    .comment-text img:first-child {
        bottom: 0;
        top: -40px;
        left: -35px;
    }

    .comment-text img:last-child {
        right: -10px;
        top: 70px;
    }

    .about-how .comment-text img:first-child {
        bottom: 0;
        top: -40px;
        left: -35px;
    }

    .about-how .comment-text img:last-child {
        right: 0;
        top: 170px;
    }
}

@media (max-width: 680px) {

    .advantage-row {
        flex-direction: column;
        padding: .9rem 0;

    }

    .differences-list .difference {
        margin: 15px 20px;
        width: 80%;
        height: 150px;
    }
}

@media (max-width: 550px)  {

    .advantages-container {
        align-items: baseline;
    }

    .comment-container {
        padding: 60px 40px;
    }

    .comment-text p {
        text-align: center;
    }

    .comment-author {
        flex-direction: column;
    }

    .comment-author span:first-child {
        margin-bottom: 10px;
    }

    .level-card {
        flex-direction: column;
    }

    .level-card:nth-child(2) .level-img:last-child {
        display: none;
    }

    .level-img.adapt {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .privilege-list h3 {
        text-align: center;
    }

    .level-content {
        padding: 0;
    }

    .level-card:nth-child(2) .level-content {
        padding-right: 0;
    }

    .possibility-card .title h3 {
        text-align: center;
    }

    .possibility-card {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .possibility-card .item {
        width: 100%;
    }

    .footer-buttons {
        padding: 70px 20px;
    }

    .footer-button {
        padding: 18px 60px !important;
    }

    .comment-text img:first-child, .comment-text img:last-child {
        width: 40%;
    }

    .comment-text img:first-child {
        bottom: 0;
        top: -60px;
        left: -35px;
    }

    .comment-text img:last-child {
        right: -35px;
        top: 150px;
    }

    .about-how .comment-text img:first-child {
        bottom: 0;
        top: -60px;
        left: -35px;
    }

    .about-how .comment-text img:last-child {
        right: -35px;
        top: 500px;
    }
}


.mobile-plan-card {
    max-width: 420px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 30px;
    border-radius: 8px;
}

.mobile-plan-card:not(:last-child) {
    margin-bottom: 30px;
}

.mobile-plan-card.pro {
    background: #FAEACD;
}

.mobile-plan-card.plus {
    background: #CCE3F1;
}

.mobile-plan-card.free {
    background: #d3d3d3;
}

.mobile-plan-card h4 {
    font-size: 28px;
    font-weight: bold;
    font-family: "Montserrat", sans-serif;
    color: #000000;
    text-align: center;
    margin: 0;
    padding-bottom: 10px;
}

.mobile-plan-card button {
    margin-top: 30px;
    margin-bottom: 25px;
    width: 100%;
    font-size: 28px;
    font-weight: bold;
    font-family: "Montserrat", sans-serif;
    color: #000000;
    text-align: center;
    border: none;
    outline: none;
    background: transparent !important;
    box-shadow: unset !important;
}

.mobile-plan-card button:hover {
    background: transparent !important;
    box-shadow: unset !important;
}

.mobile-plan-card p {
    font-size: 24px;
    font-weight: bold;
    font-family: "Montserrat", sans-serif;
    color: #000000;
    text-align: left;
    margin: 0;
}

.mobile-plan-card ul {
    list-style-type: '- ';
    padding: 0 0 0 20px;
}

.mobile-plan-card li {
    text-align: left;
    font-size: 24px;
    font-family: "Montserrat", sans-serif;
    color: #000000;
}

.mobile-plan-view {
    position: fixed;
    top: 0;
    left: 0;
    overflow-y:scroll;
    overflow-x:hidden;
    background: #FFFFFF;
    width: 100%;
    height: 100%;
    z-index: 11;
}

.mobile-plan-view h4 {
    font-size: 28px;
    font-weight: bold;
    font-family: "Montserrat", sans-serif;
    color: #000000;
    text-align: center;
}

.mobile-plan-view h5 {
    font-size: 26px;
    font-weight: bold;
    font-family: "Montserrat", sans-serif;
    color: #000000;
    text-align: center;
}

.mobile-plan-view-card {
    border-radius: 8px;
    padding: 20px 0;
}

.mobile-plan-view-card .back-btn {
    font-size: 26px;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
    color: #000000;
    text-align: center;
    text-decoration: underline;
}

.mobile-plan-view-card.pro {
    background: #faeacd;
}
.mobile-plan-view-card.pro .mobile-plan-view-card-list li {
    border-bottom: 4px solid #ECAC37;
}

.mobile-plan-view-card.plus {
    background: #CCE3F1;
}

.mobile-plan-view-card.plus .mobile-plan-view-card-list li {
    border-bottom: 4px solid royalblue;
}

.mobile-plan-view-card.free {
    background: #d3d3d3;
}

.mobile-plan-view-card h4, .mobile-plan-view-card h5 {
    padding-left: 20px;
    text-align: left;
    margin: 0;
    padding-top: 10px;
}

.mobile-plan-view-card-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.mobile-plan-view-card-list p {
    margin-top: 30px;
    margin-bottom: 10px;
    font-family: "Montserrat", sans-serif;
    font-size: 22px;
    font-weight: 600;
    text-align: left;
    padding-left: 5px;
}

.mobile-plan-view-card-list ul {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.mobile-plan-view-card-list li {
    width: 100%;
    display: flex;
    padding: 10px 0;
}

.mobile-plan-view-card-list li span {
    padding-left: 20px;
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-weight: 400;
    text-align: left;
}
