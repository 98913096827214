.admin-action-buttons{
    display: flex;
    align-items: center;
    justify-content: space-around;  
}

.admin-action-buttons .btn-lab {
    color: rgb(25, 122, 181);
}
.admin-action-buttons >p{
    cursor: pointer;
    font-size: 14px !important;
    color: rgb(25, 122, 181);
    padding: 0;
    margin: 0 0 2px;
}
.justify-left-columns{
    text-align: left;
    padding-left: 3px;
}
.side-panel-container{
    z-index:1000;
    top:0px !important;
    right:0px;
}
.p-overlaypanel .p-overlaypanel-close{
    background: #FAEACD !important;
    color: rgba(0, 0, 0, 0.55) !important;
    border-radius: 0px !important;
    width: 100% !important;
    top:0.05em !important;
    padding: 0px !important;
    height: 0.5em !important;
    text-align: right !important;
    justify-content: right !important;
}
.p-overlaypanel .p-overlaypanel-close:hover{
    box-shadow: none !important; 
}
.p-overlaypanel .p-overlaypanel-close > span{
    padding-right: 1rem;
}
/* body .p-overlaypanel .p-overlaypanel-close > span{
    padding-right: 1rem;
} */
#overlay_panel{
    z-index: 1000;
    transform-origin: center bottom;
    top: 0px !important;
    right: 0px !important;
    max-width: 400px;
    left: initial !important;
}


.tabview .tabview-header-icon span {
    margin: 0 .5rem;
}

.tabview .p-tabview p {
    line-height: 1.5;
    margin: 0;
}
 
.p-tabview-nav{
   background: #FAEACD;
}
.p-tabview-title{
    /* color:  black; */
    padding:10px;
    margin-right: 12px;
    font-size: 16px;
    font-weight: 600;
    margin-left: 15px;
}

.p-tabview .p-tabview-nav li.p-tabview-selected a {  
    font-weight: 600;
    /* text-decoration: underline; */
    border-bottom: 1px solid #000;
    padding-bottom: 3px;
    color:black;
}

.text-labels > label{
    font-size: 15px !important;
    text-align: left;
    padding: 0px 8px 8px 0px;
    margin:5px 0px;
}

.total-members{
    font-weight: 600;
}
.pipeline-members > p{
    width:150px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px; 
}
.member-close-button{

    justify-content: center;
    font-weight: 500;
    padding-left: 16px;
    cursor: pointer;
    color:#979797 !important;
}
.radio-button-info{
    font-size: 14px;
    margin-left: 26px !important;
}
.pipeline-user-label{
    margin: 17px 0px;
}

.cell-data{
    text-align: left;
}
/* .p-dialog-header{
    background-color: white !important;
} */
.p-dialog-header-icons > button{
    width: 35px;
    /* border-radius: 50%; */
}
.p-dialog-content{
    border-radius: 0px !important;
}
.p-inputtext{
    padding: 0px;
}
.p-radiobutton .p-radiobutton-box.p-highlight .p-radiobutton-iconn{
    background-color: #197AB5 !important;
}

.pipeline-save-button{
    display: flex;
    justify-content: end;
}
/* .pipeline-save-button{
    background: #ECAC37;
    border-radius: 26.5px;
    margin: 5px !important;
    height: 35px !important;
    padding: 8px 24px !important; 
    border: none;
    width: auto; 
    color: #FFFFFF;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
} */
.countries{
    display:flex;
    justify-content:left ;
    font-weight: 500;
    flex-wrap: wrap;
    padding: 5px;
}
.sectors{
    justify-content:left ;
    font-weight: 500;
}
.sector-items{
    /* display: flex;  */
    list-style: none;
    padding: 0;
}

/* .countries > span :nth-child(n+2):not(:first-child)::after{
        content: ",";
} */

.selected-countries:not(:last-child)::after{
    content: ";";
    padding: 2px;
}

.format-table-cells{
    font-weight: 500 !important;
    justify-content: left !important;
    text-align: left !important;
    padding-left: 10px!important;
}
.modal-button{
    background: #ECAC37 !important;
    border-radius: 26.5px !important;
    width: auto;
    padding: 8px 24px !important; 
    border: none !important;
    color: white;
    font-size: 13px !important;
    font-weight: 600;
}
.cell-data{
    text-align: left !important;
    padding-left: 5px !important;
    line-height: 20px;
  }
.center-cell-content{
    justify-content: center !important;
}
.pipeline-details-main{
    margin-top: 5px;
    justify-content: left;
    width: 100%;
}
.pipeline-details-pipelines{
    margin-top: 5px;
    text-align: left;
    display: flex;
    justify-content: left;
    margin-left: 1.1rem;
    color: #605F5F;
    line-height: 15px;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
}
.pipeline-detail-container{
   display: flex;
   justify-content: space-between;
   margin-left: 1.1rem;
}
.pipeline-header{
    float: left;
    text-align: left;
    width: 55vw;
    /* font-size: 25px !important; */
  
}
.header-pipeline::first-letter{
    text-transform: capitalize;
    text-align: left;
}
.pipeline-buttons{
    margin-right: 17px;
} 
.pipeline-buttons > button{
    margin: 0px 6px;
}
.pipeline-title{
    font-weight: 700;
    font-size: 20px;
    
}
.pipeline-title::first-letter{
    text-transform: capitalize
}
/* pipeline items */

.clear-btn:hover {
    background: #db2222 !important;
    border-color: #db2222 !important;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
  }
  
  .clear-btn {
    transition: background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1),
      box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1),
      transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }
  
  .suggestions-header .p-grid .p-col-2 {
    padding-top: 0 !important;
  }

  /*  my suggestion */

  .my-pipeline-suggestion{
    border-color: rgb(203, 227, 242);
    border-radius: 25px;
    border-style: solid;
    border-width: 3px;
    padding-left: 35px;
    padding-right: 35px;
    /* margin-right: 35px; */
    margin-top: 0px;
  }

  .admin-action-button{
    background: none !important;
    color: #333333 !important;
    font-size: 13px !important;
    margin: 0px !important;
    height: 35px !important;
    padding: 3px !important;
    border: none;
  }

  .field-titles-forecast:first-child {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 125px;
    margin-left: 8px;
  }
.free-user-nortification{
    display: flex;
    justify-content: center;
}
.p-tabview .p-tabview-panels{
    border: #ffffff !important;
}
.info{
    display: flex;
    justify-content: center;
    text-decoration: underline;
    margin-top: 30px;
}
.shared-with{
    color: #979797;
}
.roles-inner >span{
    color: #979797;
    font-size: 12px !important;
    font-weight: 600 !important;
    text-align: center;
    margin: auto;
}
.shared-with-total{
    padding: 6px;
    border: 2px solid #D9D9D9;
    border-radius: 20px;
    font-weight: 600;
    line-height: 20px;
    font-size: 14px;
    margin-top: 8px;
}