@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  src: url("./assets/fonts/Montserrat/Montserrat-Regular.ttf") format("ttf")
}

* {
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

.App {
  text-align: center;
  height: 100vh;
  min-width: 700px;
}

h1 {
  font-family: 'Montserrat, sans-serif';
  font-weight: 700;
  color: #187AB5;
  font-size: 45px
}

h2 {
  font-family: 'Montserrat, sans-serif';
  font-weight: 700;
  color: #187AB5;
  font-size: 40px;
}

.subtitle1 {
  font-family: 'Helvetica Neue Italic';
  color: #6C6C6C;
  /* font-size: 25px; */
}

.sectionHeader{
  text-align: left;
  margin-top: 5px;
  margin-bottom: 5px;
}

.sectionHeaderSubtitle {
  color: #6C6C6C;
  font-family: Helvetica Neue Italic;
  font-weight: 400;
  line-height: 1.75;
  text-align: left;
}

.sectionHeaderWarning {
  border: 5px solid #FCB016;
  border-radius: 25px;
  padding: 15px;
  background-color: rgba(177, 142, 142, 0.397);
  color: #000;
  font-family: Helvetica Neue Italic;
  font-weight: 600;
  line-height: 1.75;
  text-align: left;
}

h5 {
  font-size: 1.5rem;
  font-family: 'Montserrat';
  font-weight: 400;
  line-height: 1.334;
}

h6 {
  font-family: 'Montserrat, sans-serif';
  color: #187AB5;
  font-size: 16px;
  font-weight: 'bold';
}

b {
  font-family: 'Montserrat, sans-serif';
  font-weight: bold;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.popover {
  pointer-events: none;
}
/* 
.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: green;
} */

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

button.p-button {
  background-color: rgba(24, 122, 181, 1);
  border-radius: 24px;
  /* border: 0 !important; */
  color: white;
  height: 30px;
  padding: 0px 5px;
}

button.p-button.table-button {
  background-color: rgba(46, 166, 0, 1);
  border-radius: 24px;
  border: 0px;
  color: white;
  height: 20px;
  padding: 0px 5px;
  margin-right: 5px;
}

button.p-button.table-button-warning {
  background-color: #FCB016;
  border-radius: 24px;
  border: 0px;
  color: white;
  height: 20px;
  padding: 0px 5px;
  margin-right: 5px;
}

button.p-button.table-button:hover {
  background-color: rgba(24, 122, 181, 1),
}

button.round-button:hover {
  background-color: rgba(24, 122, 181, 1);
}

.p-panel-titlebar {
  background-color: rgba(24, 122, 181, 1) !important;
}

.p-panel-title {
  color: white;
}

.blurred-text {
  color: transparent !important;
  text-shadow: 0 0 8px #000 !important;
  user-select: none !important;
}

::-webkit-input-placeholder,
:-moz-placeholder,
::-moz-placeholder,
:-ms-input-placeholder,
::-ms-input-placeholder,
:placeholder-shown { /* Standard one last! */
  color: black !important;
  opacity: 1 !important;
}

.p-grid {
  margin-top: 0px !important;
}

.tyd-blur-dropdown > span.p-dropdown-label,
.tyd-blur-dropdown > .p-multiselect-label-container > .p-multiselect-label.p-placeholder {
  color: transparent !important;
  text-shadow: 0 0 8px rgba(0,0,0,0.5) !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.p-togglebutton .p-button-icon-left{
  margin-left: 10px;
  margin-top: 2px;
}