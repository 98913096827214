.notesTextarea {
  width: 90%;
  height: 200px;
  resize: vertical;
  margin: 5px;
  color: rgb(51, 51, 51);
  background: rgb(255, 255, 255);
  padding: 0.429em;
  border: 1px solid rgb(166, 166, 166);
  border-radius: 10px;
}

.notesTextarea:focus {
  outline: 0 none;
  outline-offset: 0;
  border-color: rgba(24, 122, 181, 1);
}

.file-pop-up {
  position: absolute;
  width: 180px;
  text-align: left;
  font-size: 16px;
  background: #363636;
  color: #fff;
  padding: 5px;
  top: -120px;
  left: -160px;
}

.extended-contact-table td {
  text-align: center;
}

.extended-contact-table .p-datatable-scrollable-body {
  overflow: hidden;
}

.extended-contact-table .p-datatable-scrollable-header-box {
  margin-right: 0 !important;
}

.contacts_description {
  cursor: pointer;
}

.no-attachments {
  margin-left: 10px;
  font-weight: normal;
}

#contract-input > input{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 22rem;
}
.contract-panel{
  border-radius: 20px !important;
}
#contracts{
  top: 9% !important;
  left: 36%!important;
  right: none!important;
  position: fixed !important;
}