.admin-screen {
    width: 100%;
}

.data-section {
    width: 100%;
    display: flex;
    margin-top: 20px;
    padding-right: 20px;
}

.data-block {
    padding: 20px;
    border: 3px solid rgb(250, 234, 203);
    border-radius: 35px;
    display: flex;
    flex-direction: column;
    width: 49%;
    min-height: 250px;
}

.data-block:first-child {
    margin-right: 20px;
}

.data-block h3 {
    margin: 0;
    font-size: 28px;
    font-weight: bold;
    font-family: "Montserrat", sans-serif;
    color: #000000;
    text-align: left;
}

.data-block .row-data-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.data-block .row-data {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.data-block:nth-child(2) .row-data {
    justify-content: space-between;
}

.data-block .row-data:first-child {
    margin-bottom: 30px;
}

.data-block .row-data .data-content {
    padding: 0 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.data-block .row-data .data-content:first-child,
.data-block .row-data .data-content:last-child {
    padding-left: 0;
}

.data-block:nth-child(2) .row-data:first-child {
    padding-left: 15%;
}

.data-block:nth-child(2) .row-data:last-child {
    padding-left: 9%;
}

.data-block:nth-child(2) .row-data:last-child .data-content:last-child {
    padding-right: 6%;
}

.data-block .row-data .data-content span {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    font-family: "Montserrat", sans-serif;
    color: #000000;
}

.header-checkboxes-block {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.header-checkboxes-block .header-checkbox-container label {
    margin: 0;
}

.header-checkboxes-block .header-checkbox-container:not(:last-child) {
    margin-right: 20px;
}

.admin-screen .p-panel-content {
    padding: 0 !important;
}

.admin-screen .search-btn-container {
    display: flex;
    padding: 20px 0 20px !important;
}

.api-status-section th,
.iee-suggestions-section th,
.lcp-suggestions-section th,
.user-management-section th,
.prime-predictions-section th {
    padding: 10px 0;
}

.api-status-section td,
.iee-suggestions-section td,
.lcp-suggestions-section td,
.user-management-section td,
.prime-predictions-section td {
    padding: 20px 0;
}

.api-status-section tr td .p-datatable th,
.iee-suggestions-section tr td .p-datatable th,
.lcp-suggestions-section tr td .p-datatable th,
.user-management-section tr td .p-datatable th,
.prime-predictions-section tr td .p-datatable th {
    padding: 5px 0;
}

.api-status-section tr td .p-datatable td,
.iee-suggestions-section tr td .p-datatable td,
.lcp-suggestions-section tr td .p-datatable td,
.user-management-section tr td .p-datatable td,
.prime-predictions-section tr td .p-datatable td {
    padding: 10px 0;
}
