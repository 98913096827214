.project-details-screen {
    display: flex;
    flex-direction: column;
}

.project-details-screen .header-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: rgb(203, 227, 242);
    padding: 45px 15px 35px;
}

.project-details-screen .header-section img {
    width: 220px;
    height: 38px;
}

.project-details-screen .header-section .header-data {
    display: flex;
    flex-direction: column;
    padding: 30px 35px 0;
}

.project-details-screen .header-section .header-data .header-data-content {
    display: flex;
}

.project-details-screen .header-section .header-data .header-data-content .data:first-child {
    margin-right: 200px;
}

.project-details-screen .header-section .header-data .header-data-content:last-child {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 30px;
}

.project-details-screen .header-section .header-data .header-data-content:last-child span:last-child {
    max-width: 180px;
    margin-top: 5px;
    width: 100%;
    text-align: left;
}

.project-details-screen .header-section .header-data .header-data-content:first-child span {
    font-size: 24px;
}

.project-details-screen .header-section .header-data .header-data-content:last-child span {
    font-size: 20px;
}

.project-details-screen .header-section .header-data .header-data-content:last-child span:last-child {
    font-size: 16px;
}

.project-details-screen .header-section .feedback-btn {
    position: absolute;
    top: 50px;
    right: 30px;
    width: 160px;
    height: 40px;
    background: #ECAC37;
    border-radius: 26px;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    border: none;
    cursor: pointer;
}

.project-details-screen .header-section .download-btn {
    position: absolute;
    top: 100px;
    right: 30px;
    width: 160px;
    height: 40px;
    background: #367FB6;
    border-radius: 26px;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    border: none;
    cursor: pointer;
}

.project-details-screen .project-info-section {
    padding: 50px 0 0 60px;
}

.project-details-screen .project-info-section .nav-bar {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
}

.project-details-screen .project-info-section .nav-bar .nav-item {
    font-size: 20px;
    text-decoration: underline;
    cursor: pointer;
}

.project-details-screen .project-info-section .nav-bar .nav-item:not(:last-child) {
    margin-right: 70px;
}

.project-details-screen .project-info-section .project-section-title {
    font-size: 24px;
    padding-left: 8px;
}

.project-details-screen .project-info-section .project-section-description {
    font-size: 16px;
    text-align: left;
}

.project-details-screen .project-info-section .project-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.project-details-screen .project-info-section .project-info .project-section-description {
    margin-top: 12px;
    margin-right: 50px;
    padding: 12px 0;
    border-top: 1px solid #979797;
    border-bottom: 1px solid #979797;
}

.project-details-screen .project-info-section .project-info-data {
    display: flex;
    justify-content: space-between;
}

.project-details-screen .project-info-section .project-info-data .list-data {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 22px;
    margin-right: 80px;
    padding-left: 8px;
}

.project-details-screen .project-info-section .project-info-data .list-data span {
    font-size: 20px;
    margin-bottom: 30px;
}

.project-details-screen .project-info-section .project-info-data .list-data .address span {
    margin-bottom: 0;
}

.project-details-screen .project-info-section .project-info-data .list-data .address {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.project-details-screen .project-info-section .project-info-data .list-data .address span:last-child {
    max-width: 160px;
    width: 100%;
    font-size: 16px;
    text-align: left;
    margin-top: 5px;
    margin-bottom: 30px;
}

.project-details-screen .project-info-section .project-info-data .box-data {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
    padding: 45px 40px;
    background: #D8D8D8;
}

.project-details-screen .project-info-section .project-info-data .box-data .project-section-title {
    width: 100%;
    padding-bottom: 10px;
    border-bottom: 1px solid #979797;
    text-align: left;
}

.project-details-screen .project-info-section .project-info-data .box-data .project-section-title:not(:first-child) {
    margin-top: 25px;
}

.project-details-screen .project-info-section .project-info-data .box-data .progress-block {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 25px;
}

.project-details-screen .project-info-section .project-info-data .box-data .progress-block .progress-bar-container {
    display: flex;
    flex-direction: column;
}

.project-details-screen .project-info-section .project-info-data .box-data .progress-block .progress-bar-container .progress-bar-points {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.project-details-screen .project-info-section .project-info-data .box-data .progress-block .progress-bar-container .progress-bar {
    display: flex;
}

.project-details-screen .project-info-section .project-info-data .box-data .progress-block .progress-bar-container .progress-bar .start-line {
    min-width: 195px;
    height: 20px;
    border-radius: 10px;
    background: #367FB6;
}

.project-details-screen .project-info-section .project-info-data .box-data .progress-block .progress-bar-container .progress-bar .end-line {
    width: 100%;
    height: 20px;
    border-radius: 10px;
    background: #CCE3F1;
    border: 1px solid #367FB6;
}

.project-details-screen .project-info-section .project-info-data .box-data .progress-block .progress-bar-container .progress-bar span {
    margin: 0 10px;
    white-space: nowrap;
}

.project-details-screen .project-info-section .project-info-data .box-data .progress-block .progress-bar-container .progress-bar-data-container {
    margin-top: 15px;
    padding-left: 50%;
}

.project-details-screen .project-info-section .project-info-data .box-data .progress-block .progress-bar-container .progress-bar-data-container .progress-bar-data {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 10px;
    border-bottom: 1px solid #979797;
}

.project-details-screen .project-reports-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px 50px;
}

.project-details-screen .project-reports-section .project-section-title {
    font-size: 24px;
    margin-bottom: 5px;
    padding-left: 8px;
}

.project-details-screen .project-reports-section .project-section-description {
    padding-left: 8px;
}

.project-details-screen .project-reports-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
}

.project-details-screen .project-reports-header .table-header-search {
    width: 280px;
}

.project-details-screen .project-reports-header .table-header-search::placeholder {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
}

.project-details-screen .project-reports-header .aid-blu-btn {
    width: 40px;
    height: 30px !important;
    background-color: #367FB6 !important;
    border-radius: 10px !important;
    margin-right: 5px;
}

.project-details-screen .project-reports-header .aid-blu-btn.all {
    width: 150px;
    height: 30px !important;
}

.project-details-screen .project-reports-header .aid-blu-btn span {
    color: #FFFFFF;
}

.project-details-screen .p-datatable-scrollable-header th {
    height: 40px;
}

.project-details-screen .p-datatable-scrollable-header th span {
    font-size: 14px;
    color: #FFFFFF;
}

.project-details-screen .p-datatable-scrollable-body-table td {
    height: 60px;
}

.project-details-screen .project-transaction-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 58px;
}

.project-details-screen .project-transaction-section .project-transaction-tabs {
    display: flex;
    align-items: center;
}

.project-details-screen .project-transaction-section .project-transaction-tabs button {
    width: 230px;
    height: 45px;
    display: flex;
    justify-content: center;
    border-radius: 10px !important;
    margin-right: 20px;
}

.project-details-screen .project-transaction-section .project-transaction-tabs button .p-button-label.p-c {
    display: none;
}

.project-details-screen .project-transaction-section .project-transaction-table-header {
    display: flex;
    padding: 10px;
}

.project-details-screen .project-transaction-section .project-transaction-table-header span {
    font-size: 14px;
    color: #FFFFFF;
    margin-right: 40px;
}

.project-details-screen .pi-file-o:before,
.project-details-screen .pi-window-maximize:before,
.project-details-screen .pi-window-minimize:before,
.project-details-screen .pi-file:before {
    font-size: 18px;
    font-weight: 400;
}

.project-details-screen .pi-chevron-right:before,
.project-details-screen .pi-chevron-down:before {
    font-size: 22px;
    font-weight: 700;
    color: #367FB6;
}

.project-details-screen .p-row-toggler:hover {
    background: transparent !important;
    box-shadow: unset !important;
}

.project-details-screen .p-row-toggler-icon.pi.pi-fw.p-clickable.pi-chevron-down {
    width: 100% !important;
    margin-right: 0 !important;
}

.project-details-screen .project-panels-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 50px 50px;
}

.project-details-screen .project-panels-section .project-panels-section-column:first-child {
    width: calc(50% - 45px);
    margin-right: 45px;
}

.project-details-screen .project-panels-section .project-panels-section-column:last-child {
    width: calc(50% - 45px);
}

.project-details-screen .project-panels-section .p-panel {
    width: 100%;
    border: 1px solid rgba(24, 122, 181, 1) !important;
    border-radius: 0 !important;
    background-color: rgba(24, 122, 181, 1) !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.project-details-screen .project-panels-section .p-panel img {
    display: none;
}

.project-details-screen .project-panels-section .p-panel span.pi {
    margin-right: 20px;
    color: #FFFFFF;
}

.project-details-screen .project-panels-section .p-panel .p-col {
    font-size: 24px !important;
    font-weight: 400 !important;
    color: #FFFFFF !important;
    padding: 0 30px !important;
}

.project-details-screen .project-panels-section .p-panel .p-panel-content {
    padding: 0 !important;
}

.project-details-screen .project-panels-section .p-panel .panel-item {
    display: flex;
    align-items: center;
    padding: 12px 35px;
    border-bottom: 2px solid rgba(24, 122, 181, 1);
}

.project-details-screen .project-panels-section .p-panel .panel-item:last-child {
    border-bottom-width: 1px;
}

.project-details-screen .project-panels-section .p-panel .panel-item .panel-item-title {
    max-width: 220px;
    width: 100%;
    margin-right: 15%;
    display: flex;
    justify-content: flex-start;
    text-align: left;
    font-size: 16px;
    font-weight: 700 !important;
    color: #000000;
}

.project-details-screen .project-panels-section .p-panel .panel-item .panel-item-value {
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    color: #000000;
}

.project-details-screen .project-footer-section {
    display: flex;
    padding: 0 85px 50px;
}

.project-details-screen .project-footer-section span {
    text-align: left;
    font-size: 14px !important;
    font-weight: 500 !important;
}
