:root {
    --black: 
  rgba(0, 0, 0, 1);
    --steel-blue: 
  rgba(54, 127, 182, 1);
    --scorpion: 
  rgba(96, 95, 95, 1);
    --mist-gray: 
  rgba(196, 196, 196, 1);
    --botticelli: 
  rgba(197, 222, 236, 0.231);
    --link-water: 
  rgba(204, 227, 241, 1);
    --white: 
  rgba(255, 255, 255, 1);
  --tulip-tree:( rgba(236, 172, 55, 1));
    --font-size-s: 12px;
    --font-size-xxs2: 18px;
    --font-family-montserrat: "Montserrat";
  }
  .profile-menu-items {
    color: var(--scorpion) !important;
    font-family: var(--font-family-montserrat) !important;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
  }

  .profile-menu-items > link{
    color: var(--scorpion) !important;
  }
  .profile-menu-items > link :hover{
    color: var(--scorpion) !important;
  }

.user-menu-button {
    align-items: flex-start;
    background-color:  #f0f7fb;
    border-radius: 20px;
    display: flex;
    height: 55px;
    min-width: 187px;
    padding: 9px;
    border-width: 1px;
    border-style: solid;
    border-color: #367FB6;
  }

  /* .x {
    align-items: flex-start;
    align-self: center;
    display: flex;
    height: 8px;
    margin-left: 19px;
    margin-top: 1px;
    min-width: 15px;
    padding: 0.5px 1px;
  }
  
  .x-1 {
    height: 7px;
    width: 12px;
  }
   */
  .profile-group {
    align-items: flex-start;
    display: flex;
    width: auto;
    margin: 4px;
  }

  .profile-overlap {
    align-items: flex-start;
    background-color: var(--mist-gray) !important;
    border-radius: 50%;
    display: flex;
    margin-top: 1.5px;
    border-width: 0.5px;
    border-style: solid;
    border-color: var(--mist-gray) !important;
    margin-right: 5px;
  }
  
  .profile-titles-container {
    height: 35px;
    /* margin-left: 5px; */
    position: relative;
    /* width: 150px; */
  }
  
  .profile-title{
    color: var(--black);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-xxxs);
    font-weight: 600;
    letter-spacing: 0;
    text-transform: capitalize;
  }
  

  .profile-titles-container {
    /* height: auto; */
    line-height: 1.5;
    text-align: start;
  }
  
  .profile-title > p:first-child {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 125px;
      margin-left: 8px;
  }
  
  .profile-title > p:nth-child(2) {
      text-align: left;
      margin-left: 8px;
  }

  /* user  drop down menu */
  .user-drop-down-menu {
    align-items: center;
    background-color: var(--white);
    border-radius: 20px;
    box-shadow: 2px 2px 6px  #00000021;
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    /* min-height: 242px; */
    width: 242px;
    cursor: pointer;
    z-index: 1000;
    border: 1px solid #cce3f1;
    /* padding: 3px; */
    /* margin-right: 4px !important; */
    inset: -12px !important;
    
  }

  .user-profile-view {
    background-size: 100% 100%;
    height: 72px;
    position: relative;
    width: 249px;
  }
  .user-profile-view:hover{
    background-color: #F0F7FB;
    border-radius: 20px;
    padding-left: 10px;
    width: 240px;
    /* margin-right: 15px; */
    /* color: #605F5F; */
  }
  .popup-title-container {
    /* background-color: var(--botticelli); */
    border-radius: 20px;
    height: 56px;
    left: 8px;
    position: absolute;
    top: 8px;
    width: 233px;
  }
  /* .popup-title-container:hover{
    background-color: #F0F7FB;
    border-radius: 20px;
    color: #605F5F;
  }
   */
  .popup-title-container-content{
    align-items: flex-start;
    display: flex;
    height: 41px;
    justify-content: flex-end;
    left: 16px;
    min-width: 189px;
    position: absolute;
    top: 15px;
  }
  
  .profile-view-details {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    margin-top: 0;
    min-height: 40px;
    width: 150px;
  }
  
  .profile-view-text{
    color: #00000082;
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-s);
    font-weight: 600;
    letter-spacing: 0;
    margin-top: 3px;
    min-height: 16px;
    width: 133px;
    /* text-align: center; */
  }
  
  .overlap-group {
    align-items: flex-start;
    align-self: flex-end;
    background-color: var(--mist-gray);
    border-radius: 20.5px;
    display: flex;
    height: 41px;
    margin-bottom: -0.5px;
    min-width: 41px;
  }
  .menu {
    width: 241px;
  }
  .menu-items {
    letter-spacing: 0;
    margin-left: 3px;
    min-height: 21px;
    align-items: flex-start;
    display: flex;
    padding: 10px 9px;
  }
  .menu-items >a{
    color: #605F5F;
    font-weight: 600;
    line-height: 20px;
  }
  .menu-items:hover {
      background-color: #F0F7FB;
      border-radius: 20px;
      padding-right: 5px;
      margin-right: 9px;
      color: #605F5F;
  }


/* user page */

.page-menu {
    border-radius: 35px;
    display: flex;
    height: 74px;
    width: 100%;
    border: 4px solid #D9D9D9;
  }
  
  .menu-items-top {
    align-items: flex-start;
    display: flex;
    margin-left: 16px;
    margin-top: 10px;
    min-width: 455px;
  }
  
  .menu-buttons {
    align-items: flex-start;
    display: flex;
    height: 45px;
    margin-left: 11px;
    min-width: 222px;
    padding-right: 30px;
  }
  
  .overlap-group1-2 {
    align-items: flex-end;
    background-color:   #faeacd;
    border-radius: 26.5px;
    display: flex;
    height: 47px;
    margin-left: -1px;
    margin-top: -1px;
    min-width: 222px;
    padding: 12px 32.4px;
  }
  .overlap-group1-2 :focus{
    background: rgba(236, 172, 55, 1) !important;
  }
  .upgrade-plan-2 {
    letter-spacing: 0;
    min-height: 21px;
    text-align: center;
    width: 157px;
  }
  
  .group-42 {
    align-items: flex-start;
    display: flex;
    height: 45px;
    min-width: 224px;
  }
  
  /* posible dublication */
  .overlap-group-top {
    align-items: flex-end;
    background-color: rgba(236, 172, 55, 1);
    border-radius: 26.5px;
    display: flex;
    height: 47px;
    margin-left: -1px;
    margin-top: -1px;
    min-width: 224px;
    padding: 12px 32.7px;
  }
  
  .menu-buttons-text {
    letter-spacing: 0;
    min-height: 21px;
    text-align: center;
    width: 159px;
    cursor: pointer;
  }
  .menu-buttons-text:focus{
    background-color: rgba(236, 172, 55, 1);
  }

  /*  user-profile : Preview */

  .user-panel-preview {
    align-items: center;
    border-radius: 35px;
    display: flex;
    flex-direction: column;
    margin-left: 24px;
    padding: 24px 14px;
    width: 340px;
    border: 4px solid #D9D9D9;
    margin-top: 8px;
  }

  .round-button{
    align-items: flex-end;
    background-color:rgb(250 234 205);
    border-radius: 26.5px;
    display: flex;
    height: 47px;
    min-width: 177px;
    text-align: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .round-button:active{
    /* background-color: red; */
    color: white;
  }
  .round-button > div {
    /* color: var(--white); */
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-xxxs);
    font-weight: 500;
    font-style: normal;
  }
  .preview {
    align-self: center;
    height: 288px;
    position: relative;
    /* width: 305px; */
  }
  
.preview-details {
    /* display: flex;
    flex-direction: column; */
    height: 288px;
    /* left: 3px;
    position: absolute;
    top: 0;
    width: 302px; */
  }

  .preview-details-group {
    align-items: flex-start;
    display: flex;
    height: 80px;
    justify-content: center;
    min-width: 80px;
    margin-bottom: 5px;
    justify-content: center;
  }
  .preview-details-group-img {
    align-items: flex-start;
    background-color: var(--mist-gray);
    border-radius: 40.5px;
    display: flex;
    height: 81px;
    margin-top: -0.5px;
    min-width: 81px;
    border: 1px solid;
    border-color: var(--mist-gray);
    padding-bottom: 10px;
    display: block;
  }
  .preview-details-img {
    height: 80px;
    width: 80px;
  }
  .preview-user-details {
    display: flex;
    flex-direction: column;
    height: 196px;
    margin-top: 12px;
    width: auto;
  }
  .preview-user-details-name {
    color: var(--black);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-xxs2);
    font-weight: 700;
    height: auto;
    letter-spacing: 0;
    width: 100%;
    display: flex;
   justify-content: center;
  }
  .preview-user-details-name >p{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: auto;
    /* margin-left: 8px; */
    margin: 0px;
  }

  .preview-user-details-title  {
    letter-spacing: 0;
    margin-top: 4px;
    color: var(--black);
    font-size: 13px;
    font-weight: 300;
    font-style: normal;
    text-align: center;
  }
  hr {
    border: 1px solid #d9d9d9;
    width: 100%;
  }
  .preview-user-details-email-label  {
    display: flex;
    margin-top: 5px;
    width: 100%;
  }
  .preview-user-details-email-value  {
    display: flex;
    text-align: right;
  }
  .lable-font {
    color: var(--scorpion);
    font-family: var(--font-family-montserrat);
    font-size: 13px;
    font-weight: 300;
    font-style: normal;  
  }

  .time-zone-group {
    display: flex;
    margin-top: 15px;
    width: 100%;
  }
  .time-zone-value{
    height: 21px;
    letter-spacing: 0;
    padding-left: 10px;
    text-align: right;
    width: 100%;
  }
  .country-group-value{
    height: 21px;
    letter-spacing: 0;
    text-align: right;
    width: 100%;
  }
  .round-button-text {
    letter-spacing: 0;
    min-height: 21px;
    text-align: center;
    width: 125px;
  }

  /* Account information */

  .account-information {
    border-radius: 35px;
    border: 4px solid #e0e0e0;
    align-items: flex-start;
    display: flex;
    margin-top: 5px;
  }
  
  .account-information-panel {
    align-items: flex-start;
    display: flex;
    margin-top: 16px;
  }
  .img-centered-clipped{
    object-fit: cover;
}
.profile-picture-title{
  margin-top: 15px;
}
.user-roles{
  padding:6px;
  text-transform: capitalize;
  font-size: 12px;
}

.menu-list{
  list-style: none;
  margin: 0;
  padding: 4px;
  position: relative;
}

/* menu svg */
.MuiSvgIcon-root{
    font-size: 31px !important;
}
