.faq-screen {
    width: 100%;
}

.header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.header-title {
    display: flex;
}

.header-title span {
    font-size: 28px;
    font-weight: bold;
    font-family: "Montserrat", sans-serif;
    color: #000000;
    margin-left: 5px;
}

.header-description {
    padding: 10px 35px 0 40px;
    font-size: 14px;
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
    text-align: left;
    color: #000000;
}

.faqs-container {
    display: flex;
    flex-wrap: wrap;
    padding: 25px 0;
}

.faq-block {
    min-width: 385px;
    min-height: 285px;
    padding: 35px 0;
    margin: 0 25px 20px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border: 5px solid #979797;
    border-radius: 35px;
}

.faq-block-title {
    font-size: 18px;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    color: #367FB6;
    padding-left: 35px;
    margin: 0;
}

.faq-block-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.faq-block-content {
    width: 100%;
    list-style-type: none;
    padding: 25px 0 0;
    margin: 0;
}

.tab {
    width: 100%;
    position: relative;
    padding-left: 35px;
    text-align: left;
}

.tab:not(:last-child) {
    padding-bottom: 20px;
}

.tab.create {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tab a {
    font-size: 14px;
    font-weight: 600;
    font-family: "Open Sans", sans-serif;
    color: #000000;
}

.tab-remove {
    position: absolute;
    width: 17px;
    height: 17px;
    right: 10px;
    top: 1px;
    background: #F46E6E;
    border-radius: 35px;
    padding: 2px;
    font-size: 16px;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    cursor: pointer;
}

.tab-inputs-container {
    display: flex;
    flex-direction: column;
}

.tab-input {
    max-width: 240px;
    width: 100%;
    height: 36px;
    border: 1px solid #979797;
    border-radius: 10px;
    outline: none;
    box-shadow: none;
    margin-right: 10px;
    padding: 0 10px;
    font-size: 14px;
    font-weight: 600;
    font-family: "Open Sans", sans-serif;
    color: #000000;
}

.tab-input.error,
.tab-link-input.error {
    border-color: #ff0000;
}

.tab-link-container {
    padding-top: 5px;
    position: relative;
}

.tab-link-helper {
    position: absolute;
    font-size: 15px;
    font-style: italic;
    font-weight: 500;
    font-family: "Open Sans", sans-serif;
}

.tab-link-input {
    max-width: 240px;
    width: 80%;
    border: none;
    border-bottom: 1px solid #979797;
    outline: none;
    box-shadow: none;
    margin-left: 35px;
    margin-right: 10px;
    padding-right: 10px;
    font-size: 14px;
    font-style: italic;
    font-weight: 500;
    font-family: "Open Sans", sans-serif;
    color: #000000;
}

.tab-button {
    width: 75px;
    height: 36px;
    margin-right: 16px;
    background: #CCE3F1;
    border: 1px solid #979797;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 600;
    font-family: "Open Sans", sans-serif;
    color: #000000;
}
