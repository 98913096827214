.auth-link:hover {
    text-decoration: underline;
}

.auth-btn {
    transition: background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1), transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.auth-btn:hover {
    background: rgb(139, 186, 214) !important;
    box-shadow:  0 2px 4px 0 rgba(0,0,0,0.4);
}
