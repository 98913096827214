.p-datatable-tbody td {
    word-break: break-word;
}

.no-attachments {
    margin-left: 10px;
    font-weight: normal;
}

.grants-history-table td {
    text-align: center;
}

#long-text > input{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 22rem;
    /* margin-left: 8px; */
}
#grants-overlay{
    top: 10% !important;
    left: 36%!important;
    right: none!important;
    position: fixed !important;
}