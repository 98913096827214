.p-grid {
    margin-top: 0px !important;
  }
.actions-column {
    display: flex;
    align-items: center;
    justify-content: center;
}

.actions-column img:first-child {
    margin-right: 10px;
}
