.tyd-page {
    text-align: left;
    margin-top: 45px !important;
    margin-left: 150px !important;
    margin-right: 150px !important;
}

.tyd-page-header {
    font-size: 35px;
    font-weight: 800;
    margin-top: 45px;
}

.tyd-section-header {
    font-size: 28px;
    font-weight: 700;
    margin-top: 45px;
    margin-left: 25px;
}

.tyd-section-row {
    margin-top: 45px !important;
    align-items: center;
    font-size: 20px;
}

.tyd-row-text {
    
}

.tyd-section-bottom-spacer {
    margin-bottom: 45px !important;
}

.tyd-row-header {
    font-weight: 700;
}

.tyd-my-bid-first-row {
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
}

.tyd-my-bid-last-row {
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
}

.tyd-my-bid-row {
    padding-bottom: 0 !important;
    margin-left: 25px !important;
    margin-right: 25px !important;
    padding-left: 0 !important;
}

.tyd-my-bid-border {
    border-bottom: 5px solid rgb(24, 122, 181);
}

.tyd-my-bid-col-one {
    background-color: #fddb9c;
    padding-top: 30px !important;
    padding-bottom: 30px !important;
    padding-left: 30px !important;
    padding-right:50px !important;
}