.header-subtitle {
    padding: 10px 35px 0 40px;
    font-size: 18px;
    font-weight: bold;
    font-family: "Montserrat", sans-serif;
    color: #000000;
}

.resources-cards-block {
    display: flex;
    max-width: 1300px;
    padding: 40px 35px 0 40px;
}

.cards-column:last-child {
    margin-left: 60px;
}

.card-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 560px;
    width: 100%;
    margin-bottom: 50px;
}

.card-title {
    font-size: 22px;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    color: #367FB6;
    margin: 0;
    text-align: left;
    max-width: 450px;
}

.card-list-content, .card-content {
    margin: 0;
    list-style: none;
    text-align: left;
}

.card-list-content {
    padding: 10px;
}

.card-content {
    padding: 10px 0 20px;
}

.card-list-content li {
    padding: 8px 0;
    font-size: 15px;
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
    color: #000000;
}

.card-content span {
    padding: 8px 0;
    font-size: 15px;
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
    color: #000000;
}

.card-btn-yellow {
    background: rgb(236, 172, 55);
    border-radius: 28px;
    font-size: 16px;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
    color: #FFFFFF;
    border: none;
    outline: none;
    padding: 10px 35px;
    transition: background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1), transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.card-btn-yellow:hover {
    color: #FFFFFF;
    background: rgb(214, 156, 32) !important;
    box-shadow:  0 2px 4px 0 rgba(0,0,0,0.4);
}

@media (max-width: 900px) {

    .resources-cards-block {
        flex-direction: column;
    }

    .cards-column:last-child {
        margin-left: 0;
    }

}
