.clear-btn:hover {
  background: #db2222 !important;
  border-color: #db2222 !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
}

.clear-btn {
  transition: background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.suggestions-header .p-grid .p-col-2 {
  padding-top: 0 !important;
}
.mybids-container{
  background: rgba(250, 234, 205, 0.21);
  border: 8px solid #FAEACD;
  border-radius: 8px;
  width: 77vw;
  margin: 7px;
  text-align: left !important;
  padding: 10px;
  display: flex !important;
}

.icon{
  width: 68px;
  height: 68px;
  background: #ECAC37;
  border-radius: 50%;
  padding: 10px;
  margin: 15px;
}
.icon>img{
  margin: auto;
  display: flex;
}
.bottom-footer{
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  margin-right: 5px;
  font-size: 12px;
}
.bottom-footer>p{
  text-decoration: underline;
  cursor: pointer;
}
.container-group{
  width: fit-content;
}
.links-bids{
  text-decoration: underline;
}
.hide-info-page{
  display: none;
}
.content-section {
  font-size: 16px;
  line-height: 28px;
}
