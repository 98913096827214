.faq-article-screen {
    width: 100%;

}

.header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.header-title {
    display: flex;
}

.header-title span {
    font-size: 28px;
    font-weight: bold;
    font-family: "Montserrat", sans-serif;
    color: #000000;
    margin-left: 5px;
}

.header-description {
    padding: 10px 35px 0 40px;
    font-size: 14px;
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
    text-align: left;
    color: #000000;
}

.faq-article-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 312px;
}

.faq-article-content h3 {
    font-size: 16px;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    color: #000000;
    margin: 0;
    padding: 25px 40px;
}

.faq-article-content h4 {
    font-size: 14px;
    font-weight: 600;
    font-family: "Open Sans", sans-serif;
    color: #000000;
    margin: 0;
    padding: 0 40px 10px;
}

.faq-article-content textarea {
    font-size: 14px;
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
    text-align: left;
    background: #ffffff;
    color: #000000;
    margin: 0 20px;
    padding: 10px 20px;
    min-width: 95%;
    resize: vertical;
}

.faq-article-content .rdw-editor-wrapper {
    font-size: 14px;
    background: #ffffff;
    color: #000000;
    margin: 0 20px;
    padding: 10px 20px;
    min-width: 95%;
}

.faq-article-content .rdw-editor-wrapper span {
    font-family: "Open Sans", sans-serif !important;
}

.faq-article-content textarea.disabled {
    resize: none;
    border: none;
}

.disabled .rdw-editor-toolbar {
    display: none;
}

.send-btn {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 20px 40px;
}
